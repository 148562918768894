import React from 'react'
import { Header } from '../../Components/Header/Header'
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import Intouch from '../../Components/Intouch/Intouch';
import { Footer } from '../../Components/Footer/Footer'
import './AboutPage.scss'
import { ArchiveRounded, Check, Group, MonetizationOn, Money, ThumbUp } from '@mui/icons-material';
const AboutPage = () => {
  return (
    <div className='aboutpCon'>
      <div className="servicestop about">
        <Header/>
        <div className="topcontent">
            <h1>About Us</h1>
        </div>
      </div>

    <motion.div 
        className='servicesCon'
        initial={{ y: 300}}
                whileInView={{ y: 0,
                    rotate: 0
                    }}
                transition={{type: "spring",
                bounce: 0.4,
                duration: 1}}
        >
        <div className="head">
            <div className="lefthead">
            <h4 style={{color: '#ffc03d'}}>About Us</h4>
            <h2>We Are Leading International Recovery In The World</h2>
            </div>
        </div>

        <div className="">
            <div className="content">
                
                <p>With an extensive track record spanning over half a decade, we have cultivated a reputation for close collaboration with clients, consistently achieving successful financial dispute resolution and asset recovery through a blend of legal acumen and cutting-edge technology. Our clientele spans the globe, encompassing diverse regions such as Asia, Europe, and America, reflecting our commitment to serving clients worldwide.</p>
                <p>Since our inception in 2017, we identified a pressing need for a trustworthy asset recovery firm, driven by a genuine desire to aid individuals in reclaiming their assets. Every case we undertake is approached with the utmost expertise and dedication, ensuring that each client receives personalized attention and comprehensive support throughout the process.</p>
                <p>At the heart of our operation lies a strong emphasis on customer service and the delivery of exceptional results. It's through this unwavering focus that many of our satisfied clients have enthusiastically recommended our services to their networks.</p>
                <p>Our firm prides itself on offering best-in-class services tailored to individuals who have fallen victim to unregulated investment platforms. Continuously evolving our investigative techniques and fostering world-class legal partnerships, we've earned the trust and respect of clients across the spectrum of financial fraud.</p>
                <p>We specialize in recovery services across various domains, including binary options, forex, cryptocurrencies, and international financial fraud. Leveraging the most advanced investigative methodologies, we've successfully recovered millions of dollars for victims of binary options fraud, a pervasive and lucrative industry with global implications.</p>
            </div>
            
        </div>
    </motion.div>

    <motion.div 
    className="aboutStat"
    initial={{ y: 300}}
    whileInView={{ y: 0,
        rotate: 0
        }}
    transition={{type: "spring",
    bounce: 0.4,
    duration: 1}}
    >
      <div className="content">
        <MonetizationOn fontSize='large' sx={{color: '#ffc03d'}}/>
        <div className="right">
          <h2>325</h2>
          <p>Revenue in 2019 (Million)</p>
        </div>
      </div>
      <div className="content">
        <Group fontSize='large' sx={{color: '#ffc03d'}}/>
        <div className="right">
          <h2>200</h2>
          <p>Legal Attorneys</p>
        </div>
      </div>
      <div className="content">
          <ThumbUp fontSize='large' sx={{color: '#ffc03d'}}/>
        <div className="right">
          <h2>TOP 5</h2>
          <p>Ranking in the world</p>
        </div>
      </div>
      <div className="content">
        <Check fontSize='large' sx={{color: '#ffc03d'}}/>
        <div className="right">
          <h2>9000+</h2>
          <p>Successfully Recoveries</p>
        </div>
      </div>
    </motion.div>
    <Intouch/>
    <Footer/>
    </div>
  )
}

export default AboutPage
