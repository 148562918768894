import React from 'react'
import { Header } from '../../Components/Header/Header'
import './ContactPage.scss'
import { Footer } from '../../Components/Footer/Footer'
import { motion, AnimatePresence, useAnimation } from "framer-motion";


const ContactPage = () => {
  return (
    <div>
      <div className="servicestop about">
        <Header/>
        <motion.div 
        className="topcontent"
        initial={{ opacity: 0}}
        animate={{ opacity: 1,}}
        transition={{duration: 1}}
        >
            <h1>Contact Us</h1>
        </motion.div>
      </div>

      <div className="ContactCon">
        <h3>Our Branches</h3>
        <motion.h2
            initial={{ x: -300}}
            animate={{ x: 0,
                rotate: 0
                }}
            transition={{type: "spring",
            bounce: 0.4,
            duration: 1}}
        >
            Contact Details
        </motion.h2>
        <motion.div className="content"
            initial={{ y: 300}}
            animate={{ y: 0,
                rotate: 0
                }}
            transition={{type: "spring",
            bounce: 0.4,
            duration: 1}}
        >
            <h3>Cannada Head Office</h3>
            <div className="address">
                <h4>Address:</h4>
                <p>4446 Noble Rd, Cortes Island</p>
            </div>
            
            <div className="address">
                <h4>Email:</h4>
                <p>support@capitalrecov.com</p>
            </div>
        </motion.div>
        <div className="button">
            Get Appointment
        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default ContactPage
