import React from 'react'
import { motion, AnimatePresence, useAnimation } from "framer-motion";

import './Intouch.scss'
const Intouch = () => {
  return (
    <div className='itCon'>
      <motion.div 
      className="left"
      initial={{ opacity: 0.1,}}
      whileInView={{ opacity: 1,
          rotate: 0
          }}
      transition={{type: "tween",
      bounce: 0.4,
      duration: 1}}
      >
        <h4>Sustainability</h4>
        <h2>Committed To Keeping A Safe Financial Community</h2>
        <div className="git"> <a href="/contact" style={{textDecoration: 'none', color: 'inherit'}}>Get In Touch</a> </div>
      </motion.div>
      <motion.div 
      className="right"
      initial={{ opacity: 0.1,}}
      whileInView={{ opacity: 1,
          rotate: 0
          }}
      transition={{type: "tween",
      bounce: 0.4,
      duration: 1}}
      >
        <h3>We Follow Best Practices</h3>
        <p>We follow the rules and make sure we don’t bend them, promptly and swiftly in line and on point we are  the best.</p>
        <div className="list">
          <p>Intelligence Gathering</p>
          <p>Quick Survey</p>
          <p>Legal Team</p>
          <p>Closing The Case</p>
        </div>
      </motion.div>
    </div>
  )
}

export default Intouch
