import React from 'react'
import './ServicesPage.scss'
import { Header } from '../../Components/Header/Header'
import { InfoSection } from '../../Components/InfoSection/InfoSection'
import Services from '../../Components/Servicesp/Services'
import { Footer } from '../../Components/Footer/Footer'
const ServicesPage = () => {
  return (
    <div>
      <div className="servicestop">
        <Header/>
        <div className="topcontent">
            <h1>SERVICES</h1>
        </div>
      </div>
      <Services/>
      <Footer/>
    </div>
  )
}

export default ServicesPage
