import React from 'react'
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import './Offices.scss'
const Offices = () => {
  return (
    <motion.div 
    className='servicesCon'
    initial={{ y: 300}}
    whileInView={{ y: 0,
        rotate: 0
        }}
    transition={{type: "spring",
    bounce: 0.4,
    duration: 1}}
    >
      <div className="head">
        <div className='lefthead'>
            <h4>Sustainability</h4>
            <h2>Giving Back To The Defrauded</h2>
        </div>
        <p>
        We have created a well structured support system to make your recovery process easy.
        </p>
      </div>
      <div className="locations">
        <div className="box b2">
            <h2>Cannada</h2>
            <p>4446 Noble Rd, Cortes Island</p>
        </div>
        <div className="box b1">
            <h2>United States</h2>
            <p>Deland, FL 32720</p>
        </div>
        <div className="box b3">
            <h2>Austraila</h2>
            <p>89 High Street, Kew, VIC 3101</p>
        </div>
      </div>
    </motion.div>
  )
}

export default Offices
