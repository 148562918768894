import React, { useState } from 'react'
import './InfoSection.scss'
import { useInView } from 'react-intersection-observer';
import { motion, AnimatePresence, useAnimation } from "framer-motion";


export const InfoSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const controls = useAnimation();
  const { ref, inView } = useInView();

  if (inView && !isVisible) {
    setIsVisible(true);
    controls.start({
      number: 100, // Change this to the number you want to animate to
      transition: { duration: 1, ease: "easeOut" }
    });
  }

  
  return (
    <div className='infoSection'>
        <div className="infoContainer">
            
            
          <motion.div 
          className="leftDetails"
          initial={{ opacity: 0.5, y: 400}}
          animate={{ opacity: 1, y: 0 }}
          transition={{duration: 0.5, }}
          >

            <div className="detailsContent">
                <h1>CAPITAL RECOVERY</h1> 
                <p>CAPITAL RECOVERY IS PROUD TO BE SPEARHEADING THE LEGAL RIGHTS AGAINST UNREGULATED FOREX/CRYPTO CURRIENCIES/CFD COMPANIES.  HELPING COUNTLESS FINANCIAL FRAUD VICTIMS RECOVER THEIR STOLEN FUNDS
                  
                </p>
            </div>
            <div className="ctas">
              
              <div className="startLearning"> <a href="/service" style={{textDecoration: "none", color: 'inherit'}}> Our Services</a></div>
              
              
              <div className="learnMore">   <a href="/contact" style={{textDecoration: "none", color: 'inherit'}}>Contact Us</a> </div>
              
            </div>
          </motion.div>

          <div className="rightimg"></div>
          
        </div>
    </div>
  )
}
