import React from 'react'
import './Contact.scss'
import { motion, AnimatePresence, useAnimation } from "framer-motion";
const Contact = () => {
  return (
    <div className='contactCon'>
     
            <motion.div 
            className="inner"
            initial={{ y: 300, rotate: -10,}}
            whileInView={{ y: 0,
                rotate: 0
                }}
            transition={{type: "spring",
            bounce: 0.4,
            duration: 1}}
            >
                <div className="flexes">
                <div
                    className="textSec"
                    data-aos="fade-right"
                    data-aos-offset="300"
                    data-aos-easing="ease-in-sine"
                    data-aos-duration="900"
                >
                    <h2>Get In Touch</h2>
                    <h3>Send Us A Message</h3>
                    <p></p>
                    
                </div>
                <div
                    className="contact-col"
                    id="contact-col"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                >
                    <form action="" method="POST">
                    <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Enter your name"
                        required
                    />
                    <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Enter Email Address"
                        required
                    />
                    <input
                        type="text"
                        name="subject"
                        placeholder="Enter your subject"
                        required
                    />
                    <textarea
                        rows={8}
                        id="message"
                        name="message"
                        placeholder="Message"
                        required
                    ></textarea>
                    <button type="submit" className="red-btn">
                        Send message <i className="fas fa-paper-plane"></i>
                    </button>
                    <input type="hidden" name="_captcha" value="false" />
                    </form>
                </div>
                </div>
            </motion.div>
        
    </div>
  )
}

export default Contact
