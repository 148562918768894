import React from 'react'
import { Header } from '../../Components/Header/Header'
import './Home.scss'

import { InfoSection } from '../../Components/InfoSection/InfoSection'
import { Footer } from '../../Components/Footer/Footer'
import Stats from '../../Components/Stats/Stats'
import Services from '../../Components/Servicesp/Services'
import Intouch from '../../Components/Intouch/Intouch'
import Offices from '../../Components/offices/Offices'
import Contact from '../../Components/Contact/Contact'
import bg from '../../Assets/bg.mp4'
export const Home = () => {
  return (
    <div>
      <div className="headerInfo">
        <video autoPlay loop muted>
          <source src={bg} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <Header/>
        {/* info section */}
        <InfoSection/>
      </div>
        
        <Stats/>
        <Services/>
        <Intouch/>
        <Offices/>
        <Contact/>
        <Footer/>
    </div>
  )
}
