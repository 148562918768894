import React from 'react'
import "./Footer.scss"
import { SocialIcon } from 'react-social-icons'
export const Footer = () => {
  return (
    <div className='footerCon'>
        <div className="footerLinks">
            <div className="links">
                <p>Avaliable Hours.</p>
                <a href="">Mon - Sat 8:00 - 17:30</a>
                <a href="">Sunday - CLOSED</a>
                
            </div>

            <div className="links">
                <p>Our Services</p>
                <a href="">Crypto Recovery</a>
                <a href="">Investment Scam Recovery</a>
                <a href="">Binary Option Recovery</a>
                <a href="">Fraud Investigation Service</a>
                <a href="">Asset Recovery Service</a>
            </div>

            <div className="links">
                <p>Our Location</p>
                <a href="">United States</a>
                <a href="">Austrialla</a>
                <a href="">Cannada</a>
            </div>

            <div className="social">
                <p>Social Follows</p>
                <div className="socialIcons">
                 <SocialIcon url="https://facebook.com" style={{width: "2.5rem", height: '2.5rem'}} bgColor='#fff' fgColor='#1822FF'/>
                 <SocialIcon url="https://instagram.com" style={{width: "2.5rem", height: '2.5rem'}} bgColor='#fff' />
                 <SocialIcon url="https://linkedin.com" style={{width: "2.5rem", height: '2.5rem'}} bgColor='#fff' fgColor='#1052FC'/>
                 <SocialIcon url="https://twitter.com" style={{width: "2.5rem", height: '2.5rem'}} bgColor='#fff' fgColor='#30CDFF'/>
                </div>
            </div>
        </div>
        
        <div className="footerDate">
            <p>CAPITALRECOV 2023</p>
            <p className='downtext'>© 2024 all right reserved | powered and developed by capital recovery 
            Since 2017 capital recovery is a trade name of capital recovery management ltd
            </p>
        </div>
    </div>
  )
}
