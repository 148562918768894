import React from 'react'
import { motion, AnimatePresence, useAnimation } from "framer-motion";

import './Services.scss'

const Services = () => {
  return (
    <motion.div 
    className='servicesCon'
    initial={{ y: 300}}
            whileInView={{ y: 0,
                rotate: 0
                }}
            transition={{type: "spring",
            bounce: 0.4,
            duration: 1}}
    >
      <div className="head">
        <div className="lefthead">
          <h4>Build Your Dream</h4>
          <h2>Quality Services</h2>
        </div>
      </div>

      <div className="body">
        <div className="content">
            <h3>EXPERT EVALUATION</h3>
            <p>Our seasoned team of attorneys conducts a meticulous analysis of your claims, employing top-tier expertise to strategize your recovery. We are committed to achieving results and securing the return of your assets.</p>
        </div>
        <div className="content">
            <h3>CRISIS INTERVENTION</h3>
            <p>With a blend of forensic proficiency and cutting-edge technology, we trace and recover funds across a spectrum of payment methods, including PayPal, Western Union, Bitcoin, and more. No avenue goes unexplored in our pursuit of restitution.</p>
        </div>
        <div className="content">
            <h3>Cryptocurrency Scam Retrieval</h3>
            <p>In the face of escalating cryptocurrency scams, we offer a leading-edge service dedicated to reclaiming stolen digital assets. Despite the nascent nature of crypto adoption, illicit proceeds have soared, exceeding $4.5 billion in 2019 alone.</p>
        </div>
        <div className="content">
            <h3>PROACTIVE INVESTMENT STRATEGY</h3>
            <p>Beyond recovery, we empower clients with tailored guidance for future investment endeavors. Our commitment extends beyond restitution to ensuring your financial security through informed decision-making.</p>
        </div>
        <div className="content">
            <h3>PREMIUM CLIENT CARE</h3>
            <p>Our dedication to excellence extends to our elite customer service, available round the clock. We prioritize your needs, ensuring a seamless and supportive experience throughout the recovery process.</p>
        </div>
        <div className="content">
            <h3>Investment Fraud Investigation</h3>
            <p>We help individuals and corporations large and small to recover their losses from all manner of investment scams.</p>
        </div>
      </div>
    </motion.div>
  )
}

export default Services
