import React, { useEffect, useState, useRef } from 'react'
import './Status.scss'
import { useInView } from 'react-intersection-observer';
import { motion, AnimatePresence, useAnimation } from "framer-motion";

const Stats = () => {
  const [isVisible, setIsVisible] = useState(false);
  const controls = useAnimation();
  const [currentNumber1, setCurrentNumber1] = useState(0);
  const [currentNumber2, setCurrentNumber2] = useState(0);
  const [currentNumber3, setCurrentNumber3] = useState(0);
  const [currentNumber4, setCurrentNumber4] = useState(0);
  const [intersecting, setIntersecting] = useState(false);
  const myRef = useRef();

  const increase = ()=>{
      controls.start({ opacity: 1, scale: 1 });
      const interval = setInterval(() => {
        setCurrentNumber1((prevNumber) => {
          if (prevNumber < 1250) {
            return prevNumber + 10;
          }
          return prevNumber;
        });
        setCurrentNumber2((prevNumber) => {
          if (prevNumber < 1060) {
            return prevNumber + 10;
          }
          return prevNumber;
        });
        setCurrentNumber3((prevNumber) => {
          if (prevNumber < 1550) {
            return prevNumber + 13;
          }
          return prevNumber;
        });
        setCurrentNumber4((prevNumber) => {
          if (prevNumber < 1950) {
            return prevNumber + 15;
          }
          return prevNumber;
        });
      }, 20); // Adjust the interval for smoother animation
      return () => clearInterval(interval);
    
  }

  useEffect(() => {
    
      const observer = new IntersectionObserver((entries, observer) => {
        const entry = entries[0];
        console.log('entry', entry);
        console.log('entry.isIntersecting', entry.isIntersecting);
        setIntersecting(entry.isIntersecting)
      });

      observer.observe(myRef.current);


      
    
  }, []);

  useEffect(()=>{
    if (intersecting ){
      increase()
    } 
  }, [intersecting])

  return (
    <div className='statsCon'>
      <motion.div 
      className="left"
      initial={{ opacity: 0.5, x: -400}}
      animate={{ opacity: 1, x: 0}}
      transition={{duration: 1, }}
      >
        <h4>Get Started</h4>
        <h1>License Professionals</h1>
        <p>Global fraud counsel and member of the International Chamber of Commerce (ICC) FraudNet, with local, native-speaking lawyers in practically every major country across the world.</p>
        <div className="buttonwork">
            WORK WITH US
        </div>
      </motion.div>
      
      <motion.div 
      className="right"
      initial={{ opacity: 0.1 }}
      whileInView={{ opacity: 1}}
      transition={{duration: 1, }}
      
      >
        <div 
          className="boxstat"
          ref={myRef}
          >
            <h1>{currentNumber1.toLocaleString()}+</h1>
            <p>Successful projects under cryptocurrency fund </p>
        </div>
        <div className="boxstat">
            <h1>{currentNumber2.toLocaleString()}+</h1>
            <p>Successful projects under forex</p>
        </div>
        <div className="boxstat">
            <h1>{currentNumber3.toLocaleString()}+</h1>
            <p>Successful projects in Australia</p>
        </div>
        <div className="boxstat">
            <h1>{currentNumber4.toLocaleString()}+</h1>
            <p>Successful projects in Canada</p>
        </div>
        
      </motion.div>
    </div>
  )
}

export default Stats
