import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "./Pages/Home/Home";
import ServicesPage from "./Pages/ServicesPage/ServicesPage";
import AboutPage from "./Pages/AboutPage/AboutPage";
import ContactPage from "./Pages/ContactPage/ContactPage";


function App() {
  return (
    <div className="App">

      <div class="gtranslate_wrapper"></div>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Home/>}></Route>
            <Route path="service" element={<ServicesPage/>}></Route>
            <Route path="about" element={<AboutPage/>}></Route>
            <Route path="contact" element={<ContactPage/>}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
      
    </div>
  );
}

export default App;
